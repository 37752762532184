"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_2 = __importStar(require("react"));
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var mdi_arrow_down_drop_1 = require("../../../../../../components/icons/mdi:arrow-down-drop");
var mdi_arrow_drop_up_1 = require("../../../../../../components/icons/mdi:arrow-drop-up");
var doshx_controls_web_2 = require("doshx_controls_web");
var constants_1 = require("../constants");
var challenge_1 = require("../../../../../../reducers/slices/challenge/challenge");
var react_redux_1 = require("react-redux");
var mdi_add_1 = require("../../../../../../components/icons/mdi:add");
var CustomNode = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var id = props.id, type = props.type, 
    // category,
    data = props.data, draggable = props.draggable, children = props.children, configurationComponent = props.configurationComponent, colors = props.colors, infoPopOver = props.infoPopOver, iconProp = props.icon, selected = props.selected, sourceHandle = props.sourceHandle, targetHandle = props.targetHandle;
    var className = props.className;
    console.log("data", data);
    var _a = (0, react_2.useState)(constants_1.DefaultNodeIcon), icon = _a[0], setIcon = _a[1];
    // const isGraphNode = Boolean(id);
    var nodeType = data.node_type;
    (0, react_2.useEffect)(function () {
        if (iconProp) {
            setIcon(iconProp);
        }
    }, [iconProp]);
    (0, react_2.useEffect)(function () {
        var _a, _b, _c;
        // if (isGraphNode) {
        var nodeConfig = (_a = constants_1.NodeConfigurations[nodeType]) === null || _a === void 0 ? void 0 : _a.find(function (nodeConfig) { return nodeConfig.type === type && nodeConfig.data.name === data.name; });
        if (nodeConfig) {
            if (!icon && ((_b = nodeConfig.data) === null || _b === void 0 ? void 0 : _b.icon)) {
                setIcon((_c = nodeConfig.data) === null || _c === void 0 ? void 0 : _c.icon);
            }
            // if (!data.schema && nodeConfig.data?.schema) {
            //   dispatch(setNode({ id, data: { ...data, schema: nodeConfig.data?.schema }, edit: false }));
            // }
        }
        // }
    }, [id, type, data]);
    var _b = (0, react_2.useState)(true), showConfiguration = _b[0], setShowConfiguration = _b[1];
    var _c = (0, react_2.useState)(false), hasErrors = _c[0], setHasErrors = _c[1];
    (0, react_2.useEffect)(function () {
        // if (!isGraphNode) return;
        if (data.errors && Object.keys(data.errors).length > 0) {
            setHasErrors(true);
            setShowConfiguration(true);
        }
        else if (hasErrors) {
            setHasErrors(false);
        }
    }, [data.errors]);
    var borderColor = "border-borders";
    if (props.selected) {
        borderColor = "border-tertiary";
    }
    else if (hasErrors) {
        borderColor = "border-error";
    }
    var InfoPopOver = function () { return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.PopoverControl, __assign({ content: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col max-w-sm rounded-sm" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "bold bg-blue-100 text-lg p-2 shadow" }, { children: infoPopOver === null || infoPopOver === void 0 ? void 0 : infoPopOver.summary })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-2" }, { children: infoPopOver === null || infoPopOver === void 0 ? void 0 : infoPopOver.content }))] })), behavior: "hover", position: "bottomCenter", addPadding: false }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInfoCircle, className: "text-labelText hover:text-tertiary", size: "xs" }) }))); };
    var RenderInfo = function () {
        var _a;
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center w-full divide-primary-foreground/30 divide-x gap-2 " }, { children: [(0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isIconOnly: true, size: "sm", onClick: function () { return setShowConfiguration(!showConfiguration); }, variant: "light", color: "primary", className: "text-primary-foreground" }, { children: showConfiguration ? (0, jsx_runtime_1.jsx)(mdi_arrow_drop_up_1.MdiArrowDropUp, { fontSize: 16 }) : (0, jsx_runtime_1.jsx)(mdi_arrow_down_drop_1.MdiArrowDownDrop, { fontSize: 16 }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-2 pl-2" }, { children: [icon, (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 flex-col" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row" }, { children: (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-primary-foreground text-base" }, { children: (_a = data === null || data === void 0 ? void 0 : data.name) !== null && _a !== void 0 ? _a : "Node" })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row items-center justify-between" }, { children: (0, jsx_runtime_1.jsx)("p", __assign({ className: "text-primary-foreground/70 text-[10px]" }, { children: doshx_controls_web_2.Utilities.titleCase(nodeType) })) }))] }))] }))] })));
    };
    var RenderSourceHandle = function () {
        var edges = (0, react_redux_1.useSelector)(function (state) { return state.challengeState.edges; });
        // calculate edges only on change
        var hasTargetEdge = react_2.default.useMemo(function () {
            return edges.some(function (edge) { return edge.source === id; });
        }, [edges]);
        var nextNodeTypes = react_2.default.useMemo(function () {
            return Object.keys(constants_1.NodeCategoryLabels).filter(function (category) { return !["template", "objective"].includes(category); });
        }, []);
        var MemoizedHandle = react_2.default.memo(function (_a) {
            var handle = _a.handle;
            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex rounded text-primary-foreground center items-center justify-center" }, { children: (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isIconOnly: true, size: "sm", variant: "light", color: "primary", radius: "full", className: "translate-y-[20px]", onClick: function () {
                        dispatch((0, challenge_1.setNodeModal)({ isOpen: true, nodeId: props.id, nodeTypes: nextNodeTypes }));
                    } }, { children: (0, jsx_runtime_1.jsx)(mdi_add_1.MdiAdd, { className: "center", fontSize: 24 }) })) })));
        });
        return (0, jsx_runtime_1.jsx)(MemoizedHandle, { handle: sourceHandle });
    };
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)(react_1.Card, __assign({ className: "min-w-[250px] max-w-md shadow-md border ".concat(selected ? "border-primary " : "border-transparent hover:border-primary/50"), classNames: {
                            header: "p-2",
                        } }, { children: [(0, jsx_runtime_1.jsx)(react_1.CardHeader, __assign({ className: "bg-primary text-primary-foreground rounded-none" }, { children: (0, jsx_runtime_1.jsx)(RenderInfo, {}) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "transition-all duration-300  ".concat(showConfiguration ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0", " ") }, { children: (0, jsx_runtime_1.jsx)(react_1.CardBody, __assign({ className: "py-4" }, { children: configurationComponent(data) })) }))] })), sourceHandle, targetHandle] }), RenderSourceHandle()] }));
};
exports.default = (0, react_2.memo)(CustomNode);
