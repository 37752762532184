"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeTextComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var badge_1 = require("../../../../../reducers/slices/badge/badge");
var badge_preview_1 = require("./badge_preview");
var BadgeTextComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }), selectedBadge = _a.selectedBadge, isEdited = _a.isEdited, errors = _a.errors;
    var onChange = function (badge) {
        dispatch((0, badge_1.setSelectedBadge)(__assign(__assign({}, selectedBadge), badge)));
        if (!isEdited) {
            dispatch((0, badge_1.setIsEdited)(true));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid w-full grid-cols-1 sm:grid-cols-2 xl:grid-cols-3" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col overflow-y-scroll gap-4 bg-blue-50 p-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Badge Text", textColor: "text-primary", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Name", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { disabled: true, value: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.name, onChange: null, size: "small", backgroundColor: "bg-gray-50", borderColor: "border-blue-300", textColor: "text-inputText" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Description", textColor: "text-blue-800" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.description, onChange: function (description) {
                                    onChange({ description: description });
                                }, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.description })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Sub copy", textColor: "text-blue-800" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.sub_copy, onChange: function (sub_copy) {
                                    onChange({ sub_copy: sub_copy });
                                }, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.sub_copy })] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid w-full h-full xl:col-span-2 justify-center items-center p-4 border-borders" }, { children: (0, jsx_runtime_1.jsx)(badge_preview_1.BadgePreview, {}) }))] })));
};
exports.BadgeTextComponent = BadgeTextComponent;
