"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var main_1 = require("./screens/main");
var react_redux_1 = require("react-redux");
var store_1 = require("./store");
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("@nextui-org/react");
var App = function () {
    return ((0, jsx_runtime_1.jsx)(react_redux_1.Provider, __assign({ store: store_1.redux_store }, { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.HashRouter, { children: (0, jsx_runtime_1.jsx)(react_1.NextUIProvider, { children: (0, jsx_runtime_1.jsx)(main_1.MainScreen, {}) }) }) })));
};
exports.App = App;
