"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setXrLetsachieve = exports.setUserLetsachieve = exports.devMode = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
exports.devMode = process.env.NODE_ENV !== "production";
var initialState = {
    userLetsachieve: {
        completed_challenges: 0,
        challenges_participated: 0,
        badges_earned: 0,
        player_profile: {
            Statistics: [{
                    Name: "tier",
                    Version: 0,
                    Value: 0,
                }, {
                    Name: "XP",
                    Version: 0,
                    Value: 0,
                }],
        },
    },
    xrLetsachieve: {
        tierProgression: {
            source: {
                name: "level",
                mapping: []
            },
            target: {
                name: "tier",
                mapping: [],
                milestoneName: ["tier 1", "tier 2", "tier 3", "tier 4"]
            }
        },
        levelProgression: {
            source: {
                name: "xp",
                mapping: []
            },
            target: {
                name: "level",
                mapping: [],
                milestoneName: ["level 1", "level 2", "level 3", "level 4"]
            }
        }
    },
    isLoading: false
};
var slice = (0, toolkit_1.createSlice)({
    name: "userLetsachieve",
    initialState: initialState,
    reducers: {
        setUserLetsachieve: function (state, action) {
            state.userLetsachieve = action.payload;
        },
        setXrLetsachieve: function (state, action) {
            state.xrLetsachieve = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.setUserLetsachieve = (_a = slice.actions, _a.setUserLetsachieve), exports.setXrLetsachieve = _a.setXrLetsachieve, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;
