"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputLabelComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var requiredTagComponent_1 = require("./requiredTagComponent");
var InputLabelComponent = function (_a) {
    var _b = _a.errorColor, errorColor = _b === void 0 ? "text-red-500" : _b, required = _a.required, className = _a.className, props = __rest(_a, ["errorColor", "required", "className"]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-1" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, __assign({}, props, { className: "truncate ".concat(className) })), required && (0, jsx_runtime_1.jsx)(requiredTagComponent_1.RequiredTagComponent, { color: "text-red-500", size: "base" })] })));
};
exports.InputLabelComponent = InputLabelComponent;
