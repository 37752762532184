"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgePreview = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var BadgePreview = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }), selectedBadge = _o.selectedBadge, file = _o.file;
    var theme = {
        primary: (_b = (_a = selectedBadge.theme) === null || _a === void 0 ? void 0 : _a.primary) !== null && _b !== void 0 ? _b : "#d2a023",
        name: (_d = (_c = selectedBadge.theme) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "#d2a023",
        sub_copy: (_f = (_e = selectedBadge.theme) === null || _e === void 0 ? void 0 : _e.sub_copy) !== null && _f !== void 0 ? _f : "#d2a023",
        background: (_h = (_g = selectedBadge.theme) === null || _g === void 0 ? void 0 : _g.background) !== null && _h !== void 0 ? _h : "#ffffff",
        icon: (_k = (_j = selectedBadge.theme) === null || _j === void 0 ? void 0 : _j.icon) !== null && _k !== void 0 ? _k : "#d2a023",
    };
    var pickIcon = function () {
        var _a, _b, _c, _d, _e;
        if ((_a = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _a === void 0 ? void 0 : _a.url) {
            return (0, jsx_runtime_1.jsx)("img", { src: (_b = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _b === void 0 ? void 0 : _b.url, alt: "Preview", className: "max-w-full max-h-64 mb-2 rounded" });
        }
        else if (file) {
            return (0, jsx_runtime_1.jsx)("img", { src: URL.createObjectURL(file), alt: "Preview", className: "max-w-full max-h-64 mb-2 rounded" });
        }
        else if ((_c = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _c === void 0 ? void 0 : _c.name) {
            var iconDef = (0, fontawesome_svg_core_1.findIconDefinition)({ prefix: (_d = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _d === void 0 ? void 0 : _d.prefix, iconName: (_e = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _e === void 0 ? void 0 : _e.name });
            if (!iconDef) {
                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTrophy, size: "3x", style: { color: theme.icon } });
            }
            return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: iconDef, size: "3x", style: { color: theme.icon } });
        }
        else {
            return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTrophy, size: "3x", style: { color: theme.icon } });
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex relative bg-[".concat(theme.background, "] border shadow-lg rounded-lg w-56"), style: { aspectRatio: "0.75", backgroundColor: theme.background, borderColor: theme.primary } }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col w-full items-center p-6 justify-evenly" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-24 h-24 bg-gold-300 rounded-full flex items-center justify-center", style: { backgroundColor: theme.primary } }, { children: pickIcon() })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full text-md text-center font-bold", style: { color: theme.name } }, { children: (_l = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.name) !== null && _l !== void 0 ? _l : "Name" })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full text-md text-center font-bold", style: { color: theme.sub_copy } }, { children: (_m = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.sub_copy) !== null && _m !== void 0 ? _m : "SubCopy" }))] }))] })) })));
};
exports.BadgePreview = BadgePreview;
