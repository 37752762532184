"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Box = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Box = function (_a) {
    var className = _a.className, children = _a.children, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? "bg-white" : _b, _c = _a.textColor, textColor = _c === void 0 ? "text-primary" : _c, _d = _a.variant, variant = _d === void 0 ? "outlined" : _d, _e = _a.addPadding, addPadding = _e === void 0 ? true : _e, _f = _a.borderColor, borderColor = _f === void 0 ? "border-default" : _f, _g = _a.shadowColor, shadowColor = _g === void 0 ? "shadow-default" : _g;
    var determineStyles = function () {
        var styles = [
            backgroundColor,
            textColor,
            addPadding ? "p-4" : "",
            "rounded-lg",
            // shadowColor == "shadow-none" ? "" : `shadow-lg ${shadowColor}`,
            shadowColor == "shadow-none" ? "" : "shadow-[0_0px_4px_-1px_rgba(0,0,0,0.2)]",
        ];
        switch (variant) {
            case "outlined":
                styles.push("border ".concat(borderColor));
                break;
            case "filled":
                styles.push("");
                break;
        }
        return "\n      ".concat(styles.join(" "), " \n      ").concat(backgroundColor, " ").concat(textColor, "\n    ");
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(determineStyles(), " ").concat(className, " rounded-2xl") }, { children: children })));
};
exports.Box = Box;
