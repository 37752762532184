"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.titleMap = exports.EarnConfigType = exports.excludeCategoriesTableColumns = exports.excludeProductsTableColumns = exports.excludeTypeOptions = exports.currencyTypeOptions = exports.tableColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
exports.tableColumns = [
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "created_at",
        label: "Created",
    },
    {
        displayKey: "updated_at",
        label: "Last Saved",
    },
];
exports.currencyTypeOptions = [
    {
        label: "Real Exchangeable Currency",
        value: "real",
    },
    {
        label: "Virtual Currency",
        value: "virtual",
    },
];
exports.excludeTypeOptions = [
    {
        label: "Products",
        value: "product",
    },
    {
        label: "Product Categories",
        value: "category",
    },
];
var excludeProductsTableColumns = function (onRemove) {
    return [
        {
            displayKey: "image_url",
            label: "Image",
            formatter: function (product) {
                if (product) {
                    return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
                }
                else {
                    return "";
                }
            },
        },
        {
            displayKey: "title",
            label: "Title",
        },
        {
            displayKey: "sku",
            label: "SKU",
        },
        {
            displayKey: "pid",
            label: "PID",
        },
        {
            displayKey: "action",
            label: "Action",
            formatter: function (product) {
                var onTrash = function () {
                    onRemove(product);
                };
                return (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faTrash, size: "small", borderColor: "border-borders", iconColor: "text-secondary", onClick: onTrash });
            },
        },
    ];
};
exports.excludeProductsTableColumns = excludeProductsTableColumns;
var excludeCategoriesTableColumns = function (onRemove) {
    return [
        {
            displayKey: "image_url",
            label: "Image",
            formatter: function (product) {
                if (product) {
                    return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
                }
                else {
                    return "";
                }
            },
        },
        {
            displayKey: "name",
            label: "Name",
        },
        {
            displayKey: "description",
            label: "Description",
        },
        {
            displayKey: "action",
            label: "Action",
            formatter: function (product) {
                var onTrash = function () {
                    onRemove(product);
                };
                return (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faTrash, size: "small", borderColor: "border-borders", iconColor: "text-secondary", onClick: onTrash });
            },
        }
    ];
};
exports.excludeCategoriesTableColumns = excludeCategoriesTableColumns;
var EarnConfigType;
(function (EarnConfigType) {
    EarnConfigType["Currency"] = "currency";
    EarnConfigType["Statistic"] = "statistic";
})(EarnConfigType = exports.EarnConfigType || (exports.EarnConfigType = {}));
exports.titleMap = (_a = {},
    _a[EarnConfigType.Currency] = "Cashback",
    _a[EarnConfigType.Statistic] = "XP Reward",
    _a);
