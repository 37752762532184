"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
var CurrencyRow = function (_a) {
    var currency = _a.currency, onClick = _a.onClick, onEdit = _a.onEdit, onDelete = _a.onDelete, isLoading = _a.isLoading, selected = _a.selected;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _onDelete = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm deletion of '".concat(currency === null || currency === void 0 ? void 0 : currency.name, "' currency"),
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Deleting this currency could result in multiple side effects, like your users losing access to this currency.", textColor: "text-inputText", center: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you wish to continue?", textColor: "text-inputText", center: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row pt-4 gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", loading: isLoading, onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", loading: isLoading, onClick: function () {
                                    onDelete(currency._id);
                                } })] }))] }))),
        }));
    };
    var pickIcon = function () {
        var _a, _b, _c, _d, _e;
        if ((_a = currency === null || currency === void 0 ? void 0 : currency.icon) === null || _a === void 0 ? void 0 : _a.url) {
            return (0, jsx_runtime_1.jsx)("img", { src: (_b = currency === null || currency === void 0 ? void 0 : currency.icon) === null || _b === void 0 ? void 0 : _b.url, alt: "Preview", className: "rounded" });
        }
        else if ((_c = currency === null || currency === void 0 ? void 0 : currency.icon) === null || _c === void 0 ? void 0 : _c.name) {
            var iconDef = (0, fontawesome_svg_core_1.findIconDefinition)({ prefix: (_d = currency === null || currency === void 0 ? void 0 : currency.icon) === null || _d === void 0 ? void 0 : _d.prefix, iconName: (_e = currency === null || currency === void 0 ? void 0 : currency.icon) === null || _e === void 0 ? void 0 : _e.name });
            if (!iconDef) {
                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCoins, size: "3x", style: { color: "text-primary" } });
            }
            return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: iconDef, size: "2x", style: { color: "text-primary" } });
        }
        else {
            return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCoins, size: "3x", style: { color: "text-primary" } });
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-white rounded-lg border border-borders" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center justify-between p-4  ".concat(selected ? "bg-blue-50" : "hover:bg-gray-50"), onClick: function () { return onClick(currency._id); } }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex w-16 aspect-square items-center justify-center" }, { children: pickIcon() })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: currency.name, size: "large", textColor: "text-primary", className: "ml-4" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center space-x-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "max-sm:hidden grid grid-cols-2 gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Short Code: " + currency.code, size: "small", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Conv. Rate: " + currency.conversion_rate, size: "small", textColor: "text-primary" })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faEdit, size: "small", borderColor: "border-borders", iconColor: "text-secondary", onClick: function () { return onEdit(currency._id); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faTrash, size: "small", borderColor: "border-borders", iconColor: "text-secondary", onClick: _onDelete })] }))] })) })));
};
exports.default = CurrencyRow;
