"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColourPicker = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var ColourPicker = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, className = _a.className;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 rounded-md ".concat(className) }, { children: [label ? (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, textColor: "text-primary", className: "truncate px-2 pt-2" }) : null, (0, jsx_runtime_1.jsx)("input", { type: "color", value: value, onChange: function (event) { return onChange(event.target.value); }, className: "w-full h-full min-h-[40px]" })] })));
};
exports.ColourPicker = ColourPicker;
