"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MdiAdd = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function MdiAdd(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 24 24" }, props, { children: (0, jsx_runtime_1.jsx)("path", { fill: "currentColor", d: "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" }) })));
}
exports.MdiAdd = MdiAdd;
