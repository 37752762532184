"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var badge_1 = require("../screens/console/pages/badges/badge");
var IconDropdownComponent = function (_a) {
    var value = _a.value, onSelect = _a.onSelect, className = _a.className, errorText = _a.errorText;
    var _b = (0, react_1.useState)(null), selectedIcon = _b[0], setSelectedIcon = _b[1];
    var _c = (0, react_1.useState)(""), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = (0, react_1.useState)([]), iconList = _d[0], setIconList = _d[1];
    var _e = (0, react_1.useState)([]), filteredIcons = _e[0], setFilteredIcons = _e[1];
    var _f = (0, react_1.useState)([]), displayedIcons = _f[0], setDisplayedIcons = _f[1];
    var _g = (0, react_1.useState)(true), loading = _g[0], setLoading = _g[1];
    var _h = (0, react_1.useState)(true), hasMore = _h[0], setHasMore = _h[1];
    var iconsPerPage = 120; // Number of icons to load per scroll
    (0, react_1.useEffect)(function () {
        setLoading(true);
        var allIcons = Array.from(new Set(Object.values(badge_1.collections).flatMap(function (collection) { return Object.values(collection).filter(function (icon) { return icon.iconName; }); })));
        setIconList(allIcons);
        setFilteredIcons(allIcons);
        setDisplayedIcons(allIcons.slice(0, iconsPerPage));
        setLoading(false);
    }, []);
    (0, react_1.useEffect)(function () {
        if (value === null || value === void 0 ? void 0 : value.iconName) {
            var icon = iconList.find(function (icon) { return icon.iconName === value.iconName && icon.prefix === value.prefix; });
            setSelectedIcon(icon || null);
        }
        else {
            setSelectedIcon(null);
        }
    }, [value, iconList]);
    (0, react_1.useEffect)(function () {
        var filtered = iconList.filter(function (icon) { return icon.iconName.toLowerCase().includes(searchTerm.toLowerCase()); });
        setFilteredIcons(filtered);
        setDisplayedIcons(filtered.slice(0, iconsPerPage));
        setHasMore(filtered.length > iconsPerPage);
    }, [searchTerm, iconList]);
    var handleSelect = function (icon) {
        setSelectedIcon(icon);
        onSelect(icon);
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4 ".concat(className) }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { label: "", options: displayedIcons, renderDisplay: function (icon) { return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: icon, size: "sm" }); }, value: selectedIcon, required: true, size: "small", colors: {
                borderColor: "border-blue-300",
                textColor: "text-inputText",
                iconColor: "text-inputText",
            }, onChange: handleSelect, onSearchChanged: setSearchTerm, errorText: errorText }) })));
};
exports.default = IconDropdownComponent;
