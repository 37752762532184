"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Label = function (props) {
    var className = props.className, 
    // id,
    uppercase = props.uppercase, bold = props.bold, center = props.center, _a = props.label, label = _a === void 0 ? "" : _a, _b = props.size, size = _b === void 0 ? "medium" : _b, _c = props.textColor, textColor = _c === void 0 ? "" : _c, maxLines = props.maxLines;
    var determineStyles = function () {
        var styles = [bold ? "font-bold" : "", textColor, center ? "text-center" : "", textColor];
        switch (maxLines) {
            case "one":
                styles.push("line-clamp-1");
                break;
            case "two":
                styles.push("line-clamp-2");
                break;
            case "three":
                styles.push("line-clamp-3");
                break;
            //
            case "infinite":
            default:
                break;
        }
        switch (size) {
            case "xsmall":
                styles.push("text-xs ");
                break;
            case "small":
                styles.push("text-sm ");
                break;
            case "medium":
                styles.push("text-md ");
                break;
            case "large":
                styles.push("text-lg ");
                break;
            case "xlarge":
                styles.push("text-xl ");
                break;
            case "2xlarge":
                styles.push("text-2xl ");
                break;
            case "3xlarge":
                styles.push("text-3xl ");
                break;
            case "4xlarge":
                styles.push("text-4xl ");
                break;
            case "5xlarge":
                styles.push("text-5xl ");
                break;
            case "6xlarge":
                styles.push("text-6xl ");
                break;
            case "7xlarge":
                styles.push("text-7xl ");
                break;
            case "8xlarge":
                styles.push("text-8xl ");
                break;
            case "9xlarge":
                styles.push("text-9xl ");
                break;
        }
        return styles.join(" ");
    };
    return (
    // <div htmlFor={id}
    (0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(determineStyles(), " ").concat(uppercase ? "uppercase" : "", " ").concat(className) }, { children: label })));
};
exports.Label = Label;
