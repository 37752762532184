"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeThemeComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var badge_preview_1 = require("./badge_preview");
var colourPickerComponent_1 = require("../../../../../components/colourPickerComponent");
var badge_1 = require("../../../../../reducers/slices/badge/badge");
var BadgeThemeComponent = function () {
    var _a, _b, _c, _d, _e;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }), selectedBadge = _f.selectedBadge, isEdited = _f.isEdited;
    var onColourChange = function (theme) {
        dispatch((0, badge_1.setSelectedBadge)(__assign(__assign({}, selectedBadge), { theme: __assign(__assign({}, selectedBadge.theme), theme) })));
        if (!isEdited) {
            dispatch((0, badge_1.setIsEdited)(true));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col overflow-y-scroll gap-4 bg-blue-50 p-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Badge Theme", textColor: "text-primary", bold: true }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "Primary", value: (_a = selectedBadge.theme) === null || _a === void 0 ? void 0 : _a.primary, onChange: function (primary) { return onColourChange({ primary: primary }); } }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "Name", value: (_b = selectedBadge.theme) === null || _b === void 0 ? void 0 : _b.name, onChange: function (name) { return onColourChange({ name: name }); } }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "Sub Copy", value: (_c = selectedBadge.theme) === null || _c === void 0 ? void 0 : _c.sub_copy, onChange: function (sub_copy) { return onColourChange({ sub_copy: sub_copy }); } }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "Background", value: (_d = selectedBadge.theme) === null || _d === void 0 ? void 0 : _d.background, onChange: function (background) { return onColourChange({ background: background }); } }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "Icon", value: (_e = selectedBadge.theme) === null || _e === void 0 ? void 0 : _e.icon, onChange: function (icon) { return onColourChange({ icon: icon }); } })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid w-full h-full xl:col-span-2 justify-center items-center p-4 border-borders" }, { children: (0, jsx_runtime_1.jsx)(badge_preview_1.BadgePreview, {}) }))] })));
};
exports.BadgeThemeComponent = BadgeThemeComponent;
