"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencySelectorComponent = exports.currenciesOptions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
exports.currenciesOptions = [
    { value: "ZAR", label: "🇿🇦 ZAR" },
    { value: "USD", label: "🇺🇸 USD" },
    { value: "EUR", label: "🇪🇺 EUR" },
    { value: "INR", label: "🇮🇳 INR" },
    { value: "GBP", label: "🇬🇧 GBP" },
    { value: "GHS", label: "🇬🇭 GHS" },
    { value: "IDR", label: "🇮🇩 IDR" },
    { value: "JMD", label: "🇯🇲 JMD" },
    { value: "JPY", label: "🇯🇵 JPY" },
    { value: "MKD", label: "🇲🇰 MKD" },
    { value: "PHP", label: "🇵🇭 PHP" },
    { value: "PLN", label: "🇵🇱 PLN" },
    { value: "MYR", label: "🇲🇾 MYR" },
    { value: "RUB", label: "🇷🇺 RUB" },
    { value: "SGD", label: "🇸🇬 SGD" },
    { value: "CHF", label: "🇨🇭 CHF" },
    { value: "TWD", label: "🇹🇼 TWD" },
    { value: "THB", label: "🇹🇭 THB" },
    { value: "TRY", label: "🇹🇷 TRY" },
    { value: "AED", label: "🇦🇪 AED" },
    { value: "UAH", label: "🇺🇦 UAH" },
    { value: "VND", label: "🇻🇳 VND" },
    { value: "AUD", label: "🇦🇺 AUD" },
    { value: "NAD", label: "🇳🇦 NAD" },
];
var CurrencySelectorComponent = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, _b = _a.colors, colors = _b === void 0 ? {
        labelColor: "text-black",
        textColor: "text-black",
        borderColor: "border-gray-500",
        iconColor: "text-black",
    } : _b;
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: label, required: true, options: exports.currenciesOptions, labelColor: colors === null || colors === void 0 ? void 0 : colors.labelColor, textColor: colors === null || colors === void 0 ? void 0 : colors.textColor, borderColor: colors === null || colors === void 0 ? void 0 : colors.borderColor, iconColor: colors === null || colors === void 0 ? void 0 : colors.iconColor, size: "small", value: value, errorText: "", onChange: onChange }));
};
exports.CurrencySelectorComponent = CurrencySelectorComponent;
