"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeSegment = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var get_segment_list_1 = require("../../../../api/calls/segment/get_segment_list");
var main_1 = require("../../../../reducers/slices/main");
var ChallengeSegment = function (_a) {
    var _b;
    var onSave = _a.onSave;
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), search = _c[0], setSearch = _c[1];
    var _d = (0, react_1.useState)([]), segmentOptions = _d[0], setSegmentOptions = _d[1];
    var _e = (0, react_1.useState)(""), segment_id = _e[0], setSegmentID = _e[1];
    var _f = (0, react_1.useState)(""), error = _f[0], setError = _f[1];
    var _g = (0, react_1.useState)(false), isLoading = _g[0], setIsLoading = _g[1];
    (0, react_1.useEffect)(function () {
        onSearchSegments(search);
    }, [search]);
    var onSearchSegments = function (search_string) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsLoading(true);
            (0, get_segment_list_1.get_segment_list)(__assign({}, (search_string && { search_string: search_string }))).then(function (response) {
                var segments = response === null || response === void 0 ? void 0 : response.content;
                setSegmentOptions(segments === null || segments === void 0 ? void 0 : segments.map(function (segment) { return ({
                    label: segment.name,
                    value: segment.segment_id,
                }); }));
                setIsLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    var _onSave = function () {
        if (!segment_id) {
            setError("Please select a segment");
            return;
        }
        onSave(segment_id);
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-6 justify-between items-center max-w-md" }, { children: [isLoading ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2 w-[250px]" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "User Segment", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { label: "", options: segmentOptions !== null && segmentOptions !== void 0 ? segmentOptions : [], renderDisplay: function (segment) { return segment === null || segment === void 0 ? void 0 : segment.label; }, value: (_b = segmentOptions === null || segmentOptions === void 0 ? void 0 : segmentOptions.find(function (segment) { return segment.value === segment_id; })) !== null && _b !== void 0 ? _b : {}, required: true, size: "small", colors: {
                            borderColor: "border-blue-300",
                            textColor: "text-inputText",
                            iconColor: "text-inputText",
                        }, onChange: function (segment) {
                            setSegmentID(segment === null || segment === void 0 ? void 0 : segment.value);
                        }, onSearchChanged: setSearch, errorText: error })] }))), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Please note that this step is irreversible. Once a mission is granted to a segment, the only way to remove it is by deleting the entire mission.", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row  justify-center items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Can't find a segment?", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Click here to create", onClick: function () { }, backgroundColor: "bg-transparent", textColor: "text-primary hover:text-secondary underline bold normal-case" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-6 justify-center items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", onClick: function () { return (0, main_1.setContentModal)(null); }, borderColor: "border-primary", backgroundColor: "bg-transparent", textColor: "text-primary" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Done", onClick: _onSave, backgroundColor: "bg-primary", textColor: "text-white" }) }))] }))] })));
};
exports.ChallengeSegment = ChallengeSegment;
