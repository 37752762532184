"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgePage = exports.collections = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var badge_1 = require("../../../../reducers/slices/badge/badge");
var react_router_dom_1 = require("react-router-dom");
var get_badge_thunk_1 = require("../../../../reducers/slices/badge/thunks/get_badge_thunk");
var console_1 = require("../../../../reducers/slices/console");
var badge_details_1 = require("./components/badge_details");
var badge_icon_1 = require("./components/badge_icon");
var badge_text_1 = require("./components/badge_text");
var badge_theme_1 = require("./components/badge_theme");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var save_badge_thunk_1 = require("../../../../reducers/slices/badge/thunks/save_badge_thunk");
var routes_1 = require("../../../../constants/routes");
var upload_file_1 = require("../../../../api/calls/media/upload_file");
var main_1 = require("../../../../reducers/slices/main");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var pro_light_svg_icons_2 = require("@fortawesome/pro-light-svg-icons");
var pro_thin_svg_icons_1 = require("@fortawesome/pro-thin-svg-icons");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
var errors_1 = require("../../../../helpers/errors");
exports.collections = [pro_solid_svg_icons_1.fas, pro_regular_svg_icons_1.far, pro_light_svg_icons_2.fal, pro_thin_svg_icons_1.fat, pro_duotone_svg_icons_1.fad];
fontawesome_svg_core_1.library.reset();
fontawesome_svg_core_1.library.add.apply(fontawesome_svg_core_1.library, exports.collections);
var BadgePage = function () {
    var badge_id = (0, react_router_dom_1.useParams)().badge_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }), selectedBadge = _a.selectedBadge, component = _a.component, isLoading = _a.isLoading, isEdited = _a.isEdited, isFileEdited = _a.isFileEdited, file = _a.file;
    (0, react_1.useEffect)(function () {
        if (badge_id) {
            dispatch((0, get_badge_thunk_1.get_badge_thunk)(badge_id)).then(function (_a) {
                var payload = _a.payload;
                if (!(payload === null || payload === void 0 ? void 0 : payload.success)) {
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BADGES_ROUTE));
                }
            });
        }
        else {
            dispatch((0, console_1.setPageTitle)("Create a badge"));
        }
        return function () {
            dispatch((0, badge_1.clearState)());
        };
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge._id) {
            dispatch((0, console_1.setPageTitle)("Badge - ".concat((_a = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.name) !== null && _a !== void 0 ? _a : badge_id)));
        }
    }, [selectedBadge]);
    var validate = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var errors = {
            name: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.name) ? "" : "Name is required",
            quantity: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity_limited) || (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity) || (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity) === 0 ? "" : "Quantity is required",
            description: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.description) ? "" : "Description is required",
            sub_copy: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.sub_copy) ? "" : "Sub copy is required",
            theme: {
                primary: ((_a = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.theme) === null || _a === void 0 ? void 0 : _a.primary) ? "" : "Primary theme color is required",
                name: ((_b = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.theme) === null || _b === void 0 ? void 0 : _b.name) ? "" : "Name theme color is required",
                sub_copy: ((_c = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.theme) === null || _c === void 0 ? void 0 : _c.sub_copy) ? "" : "Sub copy theme color is required",
                background: ((_d = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.theme) === null || _d === void 0 ? void 0 : _d.background) ? "" : "Background theme color is required",
                icon: ((_e = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.theme) === null || _e === void 0 ? void 0 : _e.icon) ? "" : "Icon theme color is required",
            },
            icon: "",
        };
        if (!file && !((_f = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _f === void 0 ? void 0 : _f.url) && !((_g = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.icon) === null || _g === void 0 ? void 0 : _g.name)) {
            errors.icon = "Icon is required";
        }
        if (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled) {
            errors.start_date = (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.start_date) ? "" : "Start date is required";
            errors.end_date = (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.end_date) ? "" : "End date is required";
            if ((selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.start_date) && (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.end_date)) {
                errors.end_date = new Date(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.start_date) < new Date(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.end_date) ? "" : "End date must be after start date";
            }
        }
        dispatch((0, badge_1.setErrors)(errors));
        return (0, errors_1.generateErrorList)(errors);
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorList, badgePayload, response, payload;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    errorList = validate();
                    if (errorList === null || errorList === void 0 ? void 0 : errorList.length) {
                        dispatch((0, main_1.setMessageModal)({ title: "Validation Error", messages: __spreadArray(["Please correct the following errors: ", ""], errorList, true) }));
                        return [2 /*return*/];
                    }
                    badgePayload = __assign({}, selectedBadge);
                    if (!(isFileEdited && file)) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, upload_file_1.upload_file)({
                            file: file,
                            type: "IMAGE",
                            file_name: file.name,
                            description: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.description,
                            category: "badge_icon",
                        })];
                case 1:
                    response = _e.sent();
                    if (!(response === null || response === void 0 ? void 0 : response.success) && (response === null || response === void 0 ? void 0 : response.content)) {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when uploading your icon!", messages: [response.message] }));
                        return [2 /*return*/];
                    }
                    badgePayload = __assign(__assign({}, badgePayload), { icon: { url: (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.location, etag: (_b = response === null || response === void 0 ? void 0 : response.content) === null || _b === void 0 ? void 0 : _b.etag } });
                    _e.label = 2;
                case 2:
                    if (!(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge._id)) return [3 /*break*/, 3];
                    dispatch((0, save_badge_thunk_1.save_badge_thunk)(badgePayload));
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, dispatch((0, save_badge_thunk_1.save_badge_thunk)(badgePayload))];
                case 4:
                    payload = (_e.sent()).payload;
                    if ((payload === null || payload === void 0 ? void 0 : payload.success) && ((_c = payload.content) === null || _c === void 0 ? void 0 : _c._id)) {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BADGE_DETAILS_ROUTE, "/").concat((_d = payload === null || payload === void 0 ? void 0 : payload.content) === null || _d === void 0 ? void 0 : _d._id));
                    }
                    _e.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // TODO: Once CORS problem solved, save badge preview as image for use in badge_list preview.
    // const handleSaveImage = async () => {
    //   if (badgeRef.current) {
    //     try {
    //       // const dataUrl = await toPng(badgeRef.current);
    //     } catch (error) {
    //       console.error("Error generating image:", error);
    //     }
    //   }
    // };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 pb-4 h-full" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-1 lg:grid-cols-2" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Back to badges", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BADGES_ROUTE));
                        } }) })) })), (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row gap-4 justify-between items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Customize your badge", textColor: "text-back", size: "large", bold: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "grow min-w-[125px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: onSave, loading: isLoading, enabled: isEdited || isFileEdited }) })) }))] })), (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row h-full overflow-auto", addPadding: false }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TabControl, { tabs: [
                            {
                                title: "Details",
                                content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), //component === 0 && <BadgeDetailsComponent />,
                            },
                            {
                                title: "Icon",
                                content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), //component === 1 && <BadgeIconsComponent />,
                            },
                            {
                                title: "Text",
                                content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), //component === 2 && <BadgeTextComponent />,
                            },
                            {
                                title: "Theme",
                                content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), //component === 3 && <BadgeThemeComponent />,
                            },
                        ], selectedTabIndex: component, onSelectedTabChanged: function (index) { return dispatch((0, badge_1.setComponent)(index)); }, orientation: "vertical", overflow: "overflow-none", colors: {
                            borderColor: "border-transparent",
                            tabs: {
                                textColor: "text-labelText",
                                indicatorColor: "bg-primary",
                                backgroundColor: "bg-transparent",
                                selectedBackgroundColor: "bg-white",
                                unselectedBackgroundColor: "bg-white",
                                selectedTextColor: "text-secondary",
                                unselectedTextColor: "text-labelText",
                            },
                        } }), component === 0 && (0, jsx_runtime_1.jsx)(badge_details_1.BadgeDetailsComponent, {}), component === 1 && (0, jsx_runtime_1.jsx)(badge_icon_1.BadgeIconsComponent, {}), component === 2 && (0, jsx_runtime_1.jsx)(badge_text_1.BadgeTextComponent, {}), component === 3 && (0, jsx_runtime_1.jsx)(badge_theme_1.BadgeThemeComponent, {})] }))] })));
};
exports.BadgePage = BadgePage;
