"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnhancedUserDetails = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var stepProgressBar_1 = require("../../../../../controls/stepProgressBar");
var get_reward_track_thunk_1 = require("../../../../../reducers/slices/user_letsachieve/thunks/get_reward_track_thunk");
var get_user_thunk_1 = require("../../../../../reducers/slices/user_letsachieve/thunks/get_user_thunk");
var EnhancedUserDetails = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var customerId = _a.customerId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _m = (0, react_redux_1.useSelector)(function (state) { return state.userLetsachieveState; }), userLetsachieve = _m.userLetsachieve, xrLetsachieve = _m.xrLetsachieve;
    var badges_earned = userLetsachieve.badges_earned, challenges_participated = userLetsachieve.challenges_participated, completed_challenges = userLetsachieve.completed_challenges, player_profile = userLetsachieve.player_profile;
    var _o = (_c = (_b = xrLetsachieve === null || xrLetsachieve === void 0 ? void 0 : xrLetsachieve.tierProgression) === null || _b === void 0 ? void 0 : _b.target) !== null && _c !== void 0 ? _c : {}, tiersMilestoneNames = _o.milestoneName, tiersTargetMapping = _o.mapping;
    var _p = (_e = (_d = xrLetsachieve === null || xrLetsachieve === void 0 ? void 0 : xrLetsachieve.levelProgression) === null || _d === void 0 ? void 0 : _d.target) !== null && _e !== void 0 ? _e : {}, levelMilestoneNames = _p.milestoneName, levelTargetMappingLevels = _p.mapping;
    var levelSourceMappingXp = ((_g = (_f = xrLetsachieve === null || xrLetsachieve === void 0 ? void 0 : xrLetsachieve.levelProgression) === null || _f === void 0 ? void 0 : _f.source) !== null && _g !== void 0 ? _g : {}).mapping;
    var maxTierProgression = tiersTargetMapping ? Math.max.apply(Math, tiersTargetMapping.map(Number)) : 0;
    var maxLevelProgression = levelTargetMappingLevels ? Math.max.apply(Math, levelTargetMappingLevels.map(Number)) : 0;
    var tierProgressionValue = (_k = (_j = (_h = player_profile === null || player_profile === void 0 ? void 0 : player_profile.Statistics) === null || _h === void 0 ? void 0 : _h.find(function (i) { return i.Name === "tier"; })) === null || _j === void 0 ? void 0 : _j.Value) !== null && _k !== void 0 ? _k : 0;
    var tierProgressionValuePercentage = (tierProgressionValue / maxTierProgression) * 100;
    var xpValue = (_l = player_profile === null || player_profile === void 0 ? void 0 : player_profile.Statistics.find(function (i) { return i.Name === "XP"; })) === null || _l === void 0 ? void 0 : _l.Value;
    var xpIndex = levelSourceMappingXp === null || levelSourceMappingXp === void 0 ? void 0 : levelSourceMappingXp.findIndex(function (i) { return i === String(xpValue); });
    var levelProgressionValue = xpIndex !== -1 ? Number(levelTargetMappingLevels[xpIndex]) : 0;
    var levelProgressionValuePercentage = (levelProgressionValue / maxLevelProgression) * 100;
    var walletAmount = 0;
    (0, react_1.useEffect)(function () {
        if (client === null || client === void 0 ? void 0 : client._id) {
            dispatch((0, get_user_thunk_1.get_user_thunk)({ _id: customerId }));
            dispatch((0, get_reward_track_thunk_1.get_reward_track_thunk)(null));
        }
    }, [client]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer Profile", size: "small", textColor: "text-inputText", bold: true }),
        react_1.default.createElement("div", { className: "flex flex-row gap-10 mb-8" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 w-6/12" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Tier Progression", bold: true, size: "small", textColor: "text-inputText" }),
                    react_1.default.createElement("div", { className: "self-end" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(tierProgressionValue, "/").concat(maxTierProgression), bold: true, size: "xsmall", textColor: "text-inputText" }))),
                (tiersMilestoneNames === null || tiersMilestoneNames === void 0 ? void 0 : tiersMilestoneNames.length) && react_1.default.createElement(stepProgressBar_1.StepProgressBar, { labels: tiersMilestoneNames, value: tierProgressionValuePercentage })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 w-6/12" },
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Level Progression", bold: true, size: "small", textColor: "text-inputText" }),
                    react_1.default.createElement("div", { className: "self-end" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(levelProgressionValue, "/").concat(maxLevelProgression), bold: true, size: "xsmall", textColor: "text-inputText" }))),
                (levelMilestoneNames === null || levelMilestoneNames === void 0 ? void 0 : levelMilestoneNames.length) && react_1.default.createElement(stepProgressBar_1.StepProgressBar, { labels: levelMilestoneNames, value: levelProgressionValuePercentage }))),
        react_1.default.createElement("div", { className: "flex flex-row justify-between gap-2" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Wallet Balance", size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faWallet, className: "text-green-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(walletAmount), size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Challenges Participated', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faGamepad, className: "text-blue-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(challenges_participated), size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Challenges Completed', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faGamepad, className: "text-yellow-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(completed_challenges), size: "small", textColor: "text-inputText" }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 border border-gray-300 rounded-xl p-4 py-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: 'Badges Earned', size: "small", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex items-center justify-start gap-2" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { size: "2xl", icon: pro_solid_svg_icons_1.faBadge, className: "text-orange-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(badges_earned), size: "small", textColor: "text-inputText" }))))));
};
exports.EnhancedUserDetails = EnhancedUserDetails;
