"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardsTrackMilestoneComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var colourPickerComponent_1 = require("../../../../components/colourPickerComponent");
var inputLabelComponent_1 = require("../../../../components/inputLabelComponent");
var RewardsTrackMilestoneComponent = function (_a) {
    var milestone = _a.milestone, onChange = _a.onChange, _b = _a.errors, errors = _b === void 0 ? {} : _b, index = _a.index;
    var handleOnChange = function (value) {
        onChange(__assign(__assign({}, milestone), value));
    };
    // load file from url if url and create file
    (0, react_1.useEffect)(function () {
        if (milestone.image_url) {
            fetch(milestone.image_url)
                .then(function (res) { return res.blob(); })
                .then(function (blob) {
                var file = new File([blob], milestone.image_url, { type: "image/png" });
                handleOnChange({ file: file });
            });
        }
    }, [milestone.image_url]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 col-span-2 max-w-full" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Milestone ".concat(index + 1), textColor: "text-primary", bold: true, className: "bg-blue-50 p-1" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 lg:grid-cols-4 gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid row-span-2 gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid gap-2" }, { children: [(0, jsx_runtime_1.jsx)(inputLabelComponent_1.InputLabelComponent, { label: "Name", textColor: "text-primary", required: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: milestone.name, onChange: function (name) {
                                            handleOnChange({ name: name });
                                        }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.name })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid gap-2" }, { children: [(0, jsx_runtime_1.jsx)(inputLabelComponent_1.InputLabelComponent, { label: "Colour", textColor: "text-primary", required: true }), (0, jsx_runtime_1.jsx)(colourPickerComponent_1.ColourPicker, { label: "", value: milestone.colour, onChange: function (colour) { return handleOnChange({ colour: colour }); }, className: "border border-blue-300 rounded-md p-1" })] }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid gap-2" }, { children: [(0, jsx_runtime_1.jsx)(inputLabelComponent_1.InputLabelComponent, { label: "Value", textColor: "text-primary", required: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: milestone.source_value, onChange: function (source_value) {
                                    handleOnChange({ source_value: source_value });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: errors === null || errors === void 0 ? void 0 : errors.source_value })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid gap-2" }, { children: [(0, jsx_runtime_1.jsx)(inputLabelComponent_1.InputLabelComponent, { label: "Level", textColor: "text-primary", required: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: milestone.target_value, onChange: function (target_value) {
                                    handleOnChange({ target_value: target_value });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", type: "number", errorText: errors === null || errors === void 0 ? void 0 : errors.target_value })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid row-span-2 gap-2" }, { children: [(0, jsx_runtime_1.jsx)(inputLabelComponent_1.InputLabelComponent, { label: "Image", textColor: "text-primary", required: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid gap-1" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputBoxControl, { file: milestone.file, onChange: function (file) {
                                            handleOnChange({ file: file, isFileEdited: true });
                                        }, required: true, errorText: (errors === null || errors === void 0 ? void 0 : errors.file) || (errors === null || errors === void 0 ? void 0 : errors.image_url), className: "max-h-36 overflow-hidden" }), (errors === null || errors === void 0 ? void 0 : errors.image_url) && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: errors === null || errors === void 0 ? void 0 : errors.image_url, textColor: "text-red-500", size: "xsmall", className: "pl-4" })] }))] }))] }))] }), index));
};
exports.RewardsTrackMilestoneComponent = RewardsTrackMilestoneComponent;
