"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainScreen = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var routes_1 = require("../constants/routes");
var local_storage_names_1 = require("../constants/local_storage_names");
var main_1 = require("../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var react_2 = require("react");
var user_1 = require("../reducers/slices/user/user");
var console_1 = require("./console/console");
var entry_1 = require("./entry/entry");
var privacy_policy_1 = require("./privacy_policy");
var terms_and_conditions_1 = require("./terms_and_conditions");
var react_redux_1 = require("react-redux");
var react_redux_2 = require("react-redux");
var MainScreen = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var mainRoutes = (0, react_router_dom_1.useRoutes)([
        { index: true, element: (0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: routes_1.WELCOME_ROUTE + routes_1.LOG_IN_ROUTE }) },
        { path: "".concat(routes_1.WELCOME_ROUTE, "/*"), element: (0, jsx_runtime_1.jsx)(entry_1.EntryPage, {}) },
        { path: "".concat(routes_1.CONSOLE_ROUTE, "/*"), element: (0, jsx_runtime_1.jsx)(console_1.ConsolePage, {}) },
        { path: "".concat(routes_1.TERMS_AND_CONDITIONS_ROUTE, "/*"), element: (0, jsx_runtime_1.jsx)(terms_and_conditions_1.TermsAndConditionsPage, {}) },
        { path: "".concat(routes_1.PRIVACY_POLICY_ROUTE, "/*"), element: (0, jsx_runtime_1.jsx)(privacy_policy_1.PrivacyPolicyPage, {}) },
        // { path: `${TRACK_ORDER_ROUTE}/:code?`, element: <ParcelCheckPage /> },
        // { path: `${TRACK_PARCEL_ROUTE}/:parcel_id`, element: <TrackParcelPage /> },
        // { path: `${SUCCESS_BASKET_ROUTE}/:basketID/:userID/:paymentIdentifier`, element: <SuccessBasketPayment /> }, //
        // { path: `${FAILED_BASKET_ROUTE}/:basketID/:userID/:paymentIdentifier`, element: <FailedBasketPayment /> },
        // { path: `${BASKET_PAYFAST_GATEWAY_ROUTE}/:basketID/:clientID/:userID/:name/:surname/:cellNumber/:amount/:itemName`, element: <BasketPayfastGateway /> },
        // Remember if you add a route here, you should consider if it must be added below in the useEffect, or not.
    ]);
    var _a = (0, react_redux_2.useSelector)(function (state) { return state.mainState; }), messageModal = _a.messageModal, contentModal = _a.contentModal;
    (0, react_2.useEffect)(function () {
        var userLocalStorage = localStorage.getItem(local_storage_names_1.USER_LOCAL_STORAGE);
        var heroConnectUserLocalStorage = localStorage.getItem(local_storage_names_1.HERO_CONNECT_USER_LOCAL_STORAGE);
        if (heroConnectUserLocalStorage) {
            var _heroConnectUser = JSON.parse(heroConnectUserLocalStorage);
            // dispatch(setHeroConnectUser(_heroConnectUser.user));
            // dispatch(setHeroConnectToken(_heroConnectUser.token));
        }
        if (userLocalStorage) {
            var ls = JSON.parse(userLocalStorage);
            dispatch((0, user_1.setUserToken)(ls.token));
            dispatch((0, user_1.setClientToken)(ls.client_token));
            dispatch((0, user_1.setMediaUploadToken)(ls.media_upload_token));
            dispatch((0, user_1.setPaymentEngineToken)(ls.payment_engine_token));
            dispatch((0, user_1.setUser)(ls.user));
        }
        else {
            // console.log("location.pathname", location.pathname);
            // TODO: What the hell was this `.json` file for??
            //       I'm removing it until it breaks so that we can see what it was for.
            // if (!location.pathname.includes(".json")) {
            // NB: Then here, we must look for the welcome route in the current URL before navigating to it.
            // Only navigate to welcome route if we are not already inside it.
            // check if current pathname is not in main routes
            // const matchedRoutes = matchRoutes(mainRoutes, currentURL);
            // const isMatched = matchedRoutes.length > 0;
            // console.log("isMatched", isMatched);
            if (
            // Exclude these routes from the redirection check.
            !location.pathname.includes(routes_1.WELCOME_ROUTE) &&
                !location.pathname.includes(routes_1.TERMS_AND_CONDITIONS_ROUTE) &&
                !location.pathname.includes(routes_1.PRIVACY_POLICY_ROUTE)) {
                navigate(routes_1.WELCOME_ROUTE);
            }
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "h-screen" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "h-full overflow-hidden" }, { children: mainRoutes })), (0, jsx_runtime_1.jsx)(react_1.Modal, __assign({ isOpen: Boolean(messageModal), onClose: function () { return dispatch((0, main_1.setMessageModal)(null)); } }, { children: (0, jsx_runtime_1.jsx)(react_1.ModalContent, { children: function (onclose) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.ModalHeader, __assign({ className: "flex flex-col gap-1" }, { children: messageModal.title })), (0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsx)(react_1.ModalBody, { children: messageModal.messages.map(function (message, index) {
                                    return (0, jsx_runtime_1.jsx)("div", { children: message }, "modal-message-".concat(index));
                                }) }), (0, jsx_runtime_1.jsx)(react_1.ModalFooter, { children: messageModal.buttons && messageModal.buttons.length > 0 && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-evenly gap-4 pb-4 px-4" }, { children: messageModal.buttons.map(function (button, index) {
                                        var _a;
                                        return ((0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isLoading: (_a = button.loading) !== null && _a !== void 0 ? _a : false, size: "sm", onClick: button.onClick }, { children: button.label }), "modal-message-button-".concat(index)));
                                    }) }))) })] })); } }) })), (0, jsx_runtime_1.jsx)(react_1.Modal, __assign({ isOpen: Boolean(contentModal), onClose: function () { return dispatch((0, main_1.setContentModal)(null)); }, scrollBehavior: "outside", size: "xl" }, { children: (0, jsx_runtime_1.jsx)(react_1.ModalContent, { children: function (onclose) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.ModalHeader, { children: contentModal.title }), (0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsx)(react_1.ModalBody, { children: contentModal.content })] })); } }) }))] })));
};
exports.MainScreen = MainScreen;
