"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeDetailsComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var badge_1 = require("../../../../../reducers/slices/badge/badge");
var badge_preview_1 = require("./badge_preview");
var date_fns_1 = require("date-fns");
var BadgeDetailsComponent = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.badgeState; }), selectedBadge = _b.selectedBadge, isEdited = _b.isEdited, errors = _b.errors;
    var onChange = function (badge) {
        dispatch((0, badge_1.setSelectedBadge)(__assign(__assign({}, selectedBadge), badge)));
        if (!isEdited) {
            dispatch((0, badge_1.setIsEdited)(true));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col overflow-y-scroll gap-4 bg-blue-50 p-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Badge Details", textColor: "text-primary", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Name", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.name, onChange: function (name) {
                                    onChange({ name: name });
                                }, required: true, size: "small", backgroundColor: "bg-white", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors === null || errors === void 0 ? void 0 : errors.name })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Enable Dates", value: selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled, onChange: function (dates_enabled) {
                            onChange({ dates_enabled: dates_enabled });
                        }, textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "From Date", textColor: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled) ? "text-primary" : "text-disabled" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { required: true, errorTextColor: "text-red-400", backgroundColor: "bg-white", borderColor: "border-blue-300", value: selectedBadge.start_date ? new Date(selectedBadge.start_date) : null, onChange: function (start_date) {
                                    var date = new Date(start_date);
                                    onChange({ start_date: (0, date_fns_1.startOfDay)(date) });
                                }, size: "small", errorText: errors.start_date, disabled: !(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "To Date", textColor: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled) ? "text-primary" : "text-disabled" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { required: true, errorTextColor: "text-red-400", backgroundColor: "bg-white", borderColor: "border-blue-300", value: selectedBadge.end_date ? new Date(selectedBadge.end_date) : null, onChange: function (end_date) {
                                    var date = new Date(end_date);
                                    onChange({ end_date: (0, date_fns_1.endOfDay)(date) });
                                }, size: "small", errorText: errors.end_date, disabled: !(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.dates_enabled) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Quantity Type", textColor: "text-primary" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "", value: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity_limited) ? "limited" : "unlimited", onChange: function (quantity_limited) {
                                        onChange({ quantity_limited: quantity_limited === "limited" });
                                    }, required: true, size: "small", options: [
                                        { label: "Unlimited", value: "unlimited" },
                                        { label: "Limited", value: "limited" },
                                    ], borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors.quantity_type }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Quantity", textColor: (selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity_limited) ? "text-primary" : "text-disabled" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: ((_a = selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity) !== null && _a !== void 0 ? _a : 0).toString(), onChange: function (quantity) {
                                    onChange({ quantity: +quantity });
                                }, required: true, disabled: !(selectedBadge === null || selectedBadge === void 0 ? void 0 : selectedBadge.quantity_limited), type: "number", size: "small", borderColor: "border-blue-300", textColor: "text-inputText", errorText: errors.quantity, backgroundColor: "bg-white" })] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid w-full h-full xl:col-span-2 justify-center items-center p-4 border-borders", style: {
                    backgroundImage: "radial-gradient(circle, #000 1%, transparent 1%)",
                    backgroundSize: "20px 20px",
                } }, { children: (0, jsx_runtime_1.jsx)(badge_preview_1.BadgePreview, {}) }))] })));
};
exports.BadgeDetailsComponent = BadgeDetailsComponent;
