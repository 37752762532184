"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateLoyaltyDetails = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var add_loyalty_1 = require("../../../../../api/calls/loyalty/add/add_loyalty");
var constants_1 = require("../../../../../constants/constants");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var loyalty_1 = require("../../../../../reducers/slices/loyalty/loyalty");
var get_loyalty_thunk_1 = require("../../../../../reducers/slices/loyalty/thunks/get/get_loyalty_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var constants_2 = require("../constants");
var get_client_user_branches_1 = require("../../../../../api/calls/client/get_client_user_branches");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var constants_3 = require("../../billing/loyalty/constants");
var CreateLoyaltyDetails = function (_a) {
    var _b, _c, _d, _e;
    var customerId = _a.customerId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _f = (0, useSearchDebounce_1.useSearchDebounce)(900), searchDashboardBranches = _f[0], setSearchDashboardBranches = _f[1];
    var selectedLoyalty = (0, react_redux_1.useSelector)(function (state) { return state.loyaltyState; }).selectedLoyalty;
    var _g = (0, react_1.useState)(false), isInteracted = _g[0], setIsInteracted = _g[1];
    var customer = (0, react_redux_1.useSelector)(function (state) { return state.customerState; }).customer;
    var clientUsers = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).clientUsers;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), dashboardBranches = _h.dashboardBranches, dashboardBranchPagination = _h.dashboardBranchPagination;
    var _j = (0, validateForm_1.validateForm)(selectedLoyalty, { enabled: isInteracted }), errors = _j.errors, isValid = _j.isValid;
    var _k = (0, validateForm_1.validateForm)(selectedLoyalty.card, {
        enabled: isInteracted,
        validationConfig: constants_2.cardValidationConfig,
    }), cardErrors = _k.errors, cardIsValid = _k.isValid;
    var query = __assign(__assign({}, dashboardBranchPagination), { is_dashboard_request: true });
    //clientUser branches
    var _l = (0, react_1.useState)([]), clientUserBranches = _l[0], setClientUserBranches = _l[1];
    var _m = (0, react_1.useState)(null), selectedBranchId = _m[0], setSelectedBranchId = _m[1];
    var _o = (0, react_1.useState)(""), branch = _o[0], setBranch = _o[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, loyalty_1.setSelectedLoyalty)(constants_3.initialLoyaltyValues));
    }, []);
    (0, react_1.useEffect)(function () {
        if (clientUsers) {
            getLoggedInUserBranches();
        }
    }, [clientUsers]);
    (0, react_1.useEffect)(function () {
        onSearchBranches(searchDashboardBranches);
    }, [searchDashboardBranches]);
    var getBranches = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchBranches = function (search_string) {
        getBranches(__assign(__assign(__assign({}, dashboardBranchPagination), (search_string && { search_string: search_string })), { is_dashboard_request: true }));
    };
    var _setLoyalty = function (value) {
        dispatch((0, loyalty_1.setSelectedLoyalty)(__assign(__assign({}, selectedLoyalty), value)));
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var _createLoyalty = function () { return __awaiter(void 0, void 0, void 0, function () {
        var card, payload, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    card = selectedLoyalty.card, payload = __rest(selectedLoyalty, ["card"]);
                    return [4 /*yield*/, (0, add_loyalty_1.add_loyalty)(__assign(__assign({}, payload), { cell_number: customer.cell_number, member_card_number: selectedLoyalty.card.number, linked: selectedLoyalty.card.linked, member_branch_id: selectedBranchId }))];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, main_1.setContentModal)(null));
                        dispatch((0, get_loyalty_thunk_1.get_loyalty_thunk)({ customer_id: customerId }));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var getLoggedInUserBranches = function () { return __awaiter(void 0, void 0, void 0, function () {
        var loggedInUser, response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    loggedInUser = clientUsers === null || clientUsers === void 0 ? void 0 : clientUsers.find(function (clientUser) { return clientUser.user_id === (user === null || user === void 0 ? void 0 : user._id); });
                    console.log("loggedInUser", loggedInUser);
                    if (!(user && (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN")) return [3 /*break*/, 1];
                    (0, get_client_user_branches_1.get_logged_in_client_user_branches)({
                        branch_id_list: loggedInUser === null || loggedInUser === void 0 ? void 0 : loggedInUser.manager_of,
                    }).then(function (response) {
                        var _a, _b, _c;
                        if (response.success) {
                            setClientUserBranches((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.branch_list);
                            setSelectedBranchId((_c = (_b = response === null || response === void 0 ? void 0 : response.content) === null || _b === void 0 ? void 0 : _b.branch_list[0]) === null || _c === void 0 ? void 0 : _c._id);
                        }
                    });
                    return [3 /*break*/, 3];
                case 1:
                    if (!((user === null || user === void 0 ? void 0 : user.role) === "ADMIN")) return [3 /*break*/, 3];
                    return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(__assign(__assign({}, dashboardBranchPagination), query)))];
                case 2:
                    response = _d.sent();
                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                        setClientUserBranches((_c = (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.branch_list);
                    }
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.loyalty_number, onChange: function (loyalty_number) {
                                _setLoyalty({ loyalty_number: loyalty_number });
                            }, errorText: errors.loyalty_number, label: "Loyalty number", backgroundColor: "bg-gray", borderColor: "border-transaparent", size: "small" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: (_b = selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card) === null || _b === void 0 ? void 0 : _b.number, onChange: function (number) {
                                var linked = (selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card).linked;
                                _setLoyalty({ card: { number: number, linked: linked } });
                            }, errorText: cardErrors === null || cardErrors === void 0 ? void 0 : cardErrors.number, label: "Loyalty card number", backgroundColor: "bg-gray", borderColor: "border-transaparent", size: "small" }) })), (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (branch) { return branch === null || branch === void 0 ? void 0 : branch.label; }, label: "Select a branch...", options: (_c = dashboardBranches === null || dashboardBranches === void 0 ? void 0 : dashboardBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })) !== null && _c !== void 0 ? _c : [], size: "small", value: branch, colors: {
                            borderColor: "border-transaparent",
                            iconColor: "text-primary",
                        }, errorText: "", onChange: function (branchObject) {
                            setBranch(branchObject);
                            setSelectedBranchId(branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                            _setLoyalty({ member_branch_id: branchObject === null || branchObject === void 0 ? void 0 : branchObject.value });
                        }, onSearchChanged: function (search_string) {
                            setSearchDashboardBranches(search_string);
                        } })), (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (branch) { return branch === null || branch === void 0 ? void 0 : branch.label; }, label: "Select a branch...", options: (_d = clientUserBranches === null || clientUserBranches === void 0 ? void 0 : clientUserBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })) !== null && _d !== void 0 ? _d : [], size: "small", value: branch, colors: {
                            borderColor: "border-transaparent",
                            iconColor: "text-primary",
                        }, errorText: "", onChange: function (branchObject) {
                            setBranch(branchObject);
                            setSelectedBranchId(branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                            _setLoyalty({ member_branch_id: branchObject === null || branchObject === void 0 ? void 0 : branchObject.value });
                        }, onSearchChanged: function (search_string) {
                            setSearchDashboardBranches(search_string);
                        } })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Link to Physical Card", decorationColor: "decoration-borders", uncheckedColor: "text-textInput", checkedColor: "text-success", value: (_e = selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card) === null || _e === void 0 ? void 0 : _e.linked, errorText: cardErrors === null || cardErrors === void 0 ? void 0 : cardErrors.linked, size: "xsmall", textColor: "text-textInput", onChange: function (linked) {
                                var number = (selectedLoyalty === null || selectedLoyalty === void 0 ? void 0 : selectedLoyalty.card).number;
                                _setLoyalty({ card: { linked: linked, number: number } });
                            } }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex pt-4 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", enabled: isValid && cardIsValid, label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: _createLoyalty })] }))] })));
};
exports.CreateLoyaltyDetails = CreateLoyaltyDetails;
