"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT_TOPICS = exports.challengeVoucherTypeOptions = exports.challengeVirtualCurrencyOptions = exports.challengeCurrencyTypeOptions = exports.challengeStatusOptions = exports.challengeEventTypeOptions = exports.challengeRewardOptions = exports.challengeScopeOptions = exports.tableColumns = void 0;
exports.tableColumns = [
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "created_at",
        label: "Created",
    },
    {
        displayKey: "updated_at",
        label: "Last Saved",
    },
];
exports.challengeScopeOptions = [
    {
        label: "Entire Basket",
        value: "basket",
    },
    {
        label: "Category",
        value: "category",
    },
    {
        label: "Product",
        value: "product",
    },
];
exports.challengeRewardOptions = [
    // {
    //   label: "Vouchers",
    //   value: "vouchers",
    // },
    {
        label: "Badges",
        value: "badges",
    },
    // {
    //   label: "Currency",
    //   value: "currency",
    // },
    {
        label: "XP Points",
        value: "xp",
    },
];
exports.challengeEventTypeOptions = [
    {
        label: "Number of Logins",
        value: "numberOfLogins",
    },
    {
        label: "Order Value",
        value: "orderValue",
    },
    {
        label: "Basket Value",
        value: "basketValue",
    },
    {
        label: "Order Volume",
        value: "orderVolume",
    },
];
exports.challengeStatusOptions = [
    {
        label: "Draft",
        key: "draft",
    },
    {
        label: "Staging",
        key: "staging",
    },
    {
        label: "Released",
        key: "released",
    },
];
exports.challengeCurrencyTypeOptions = [
    {
        label: "GBP",
        value: "GBP",
    },
    {
        label: "USD",
        value: "USD",
    },
    {
        label: "EUR",
        value: "EUR",
    },
];
exports.challengeVirtualCurrencyOptions = [
    {
        label: "VC 1",
        value: "vc1",
    },
    {
        label: "VC 2",
        value: "vc2",
    },
    {
        label: "VC 3",
        value: "vc3",
    },
];
exports.challengeVoucherTypeOptions = [
    {
        label: "Discount",
        value: "discount",
    },
    {
        label: "Free Delivery",
        value: "freeDelivery",
    },
    {
        label: "Free Product",
        value: "freeProduct",
    },
];
// Please note the root keys (and prefix for topic) are the model name as seen in mongodb
exports.EVENT_TOPICS = {
    users: {
        CREATED: "users/created",
        UPDATED: "users/updated",
        LOGGED_IN: "users/logged-in",
    },
    baskets: {
        CREATED: "baskets/created",
        UPDATED: "baskets/updated",
    },
    orders: {
        CREATED: "orders/created",
        VIEWED: "orders/viewed",
        UPDATED: "orders/updated",
        STATUS_CHANGE: "orders/status-change",
    },
    parcels: {
        CREATED: "parcels/created",
        PACKED: "parcels/packed",
        FULFILLED: "parcels/fulfilled",
        UPDATED: "parcels/updated",
        STATUS_CHANGE: "parcels/status-change",
    },
    loyaltyprograms: {
        CREATED: "loyaltyprograms/created",
        UPDATED: "loyaltyprograms/updated",
    },
};
